import React from 'react'
import api from '../lib/api'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Header from '../components/header'
import { CustomWorkForm, TextButton } from '../components/forms'

class CustomEngravingPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			quantity: 0,
			info: '',
			name: '',
			email: '',
			phone: '',
			company: '',
			cup_type: 'bring_own',
			sent: false
		}
		this.emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
	}
	updateField = (event) => {
		const new_value = event.target.value;
		this.setState({ [event.target.name]: new_value });
	}
	emailValid = () => {
		return this.emailRegex.test(this.state.email);
	}
	valid = () => {
		const s = this.state;
		return s.quantity > 0 && s.name.length > 2 && this.emailValid() && s.phone.length > 5;
	}
	submit = async () => {
		if(this.valid()) {
			try {
				let req = { ...this.state };
				const response = await api.contact({ subject: 'Custom Engraving Request', request: req });
				if(response.error) {
					// messages.show('CONTACT_FAILED');
					return;
				}
				this.setState({ sent: true });
				// messages.show('CONTACT_SENT');
			} catch(e) {
				// messages.show('CONTACT_FAILED');
			}
		}
	}
	render() {
		return (
			  <Layout>
			  	<div>
			  		<div className='w-100 w-80-ns center'>
			  			<Header />
			  			<div className='mt4-ns pv4 mh4 mh0-ns'>
			  				<div>
			  					<h2 className='roboto f3 fw4 near-black'>Custom engraving.</h2>
			  					<p className='near-black lh-copy roboto'>Use the form below to tell us what you have in mind. We'll get back to you ASAP with follow-up questions and a quote.<br/><span className='fw6'>We respond to all inquiries within 1 business day.</span></p>
			  				</div>
			  			</div>
			  			<div className='cf pt0 pt4-ns pb4 mh4 mh0-ns mb4'>
			  				<div className='w-100 w-50-l fl'>
			  					<CustomWorkForm data={ this.state } onChange={ this.updateField } />
			  					<div className='tr mt3'>
			  						{ !this.state.sent &&
			  							<TextButton disabled={ !this.valid() } onClick={ this.submit } title='Send' />
			  						}
			  						{ this.state.sent &&
			  							<p className='near-black roboto lh-copy anim-in-slow ba b--black-10 dib pa2 ph3'>Thanks! Message sent.</p>
			  						}
			  					</div>
			  				</div>
			  			</div>
			  		</div>
				</div>
			  </Layout>
		)
	}


}

export default CustomEngravingPage